.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

* {
  margin: 0;
  
}

.namenav {
  position: relative;
}

.myname {
  display: flex;
  justify-content: flex-start;
  position: absolute;
  font-size: 3rem;
}

.mainnav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  list-style: none;
  gap: .5rem;
}

.line {
  height: 3rem;
  width: 100rem;
  color: black;
}

.block {
  height: 4rem;
  width: 100%;
  /* background-color: black; */
  position: absolute;
  margin-left: -55rem;
}

.block-2 {
  height: 4rem;
  width: 100%;
  /* background-color: black; */
  position: absolute;
  margin-left: 55rem;
  transform: scaleX(-1);
}

.thin-line {
  margin-top: .5rem;
  height: .5px;
  width: 420px;
  /* margin-left: 3.5rem; */
  background-color: #282c34;
}

.frame-animation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* border: red solid 1px; */
  height: 100%;
  width: 170%;
}

.frame-animation-image {
  margin-top: 20rem;
  height: 80%;
  width: 100%;
  position: relative;
  /* opacity: .3; */
}

.frame-animation-menu {
  display: flex;
  
  gap: 3rem;
  margin-top: 14rem;
  position: absolute;
}

.floatinng-menu-1 {
  display: flex;
  flex-direction: column;
  gap: 2rem;

}

.floatinng-menu-2 {
  display: flex;
  flex-direction: column;
  gap: 2rem;

}

.title-resume{
  height: 5rem;
  margin: 2rem;
}

.resume-background {
  height: 580px;
  width: 450px;
  background-color: #F6CA8F;
}

.title-resume-shadow {
  position: absolute;
  margin-left: 20rem;
  height: 20rem;
  opacity: .2;
  z-index: -2;
}

.resume-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.real-resume {
  height: 35rem;
  position: absolute;
}

.inside-resume {
  height: 100%;
  width: 100%;
}

.macbook {
  height: 150px;
  
}

.projects-container {
  display: flex;
  background-color: #F6CA8F;
  flex-direction: row;
  flex-wrap: wrap;
  height: 670px;
  width: 600px;
  /* border: red solid 2px; */
}

.block-project {
  height: 200px;
  width: 250px;
  opacity: .9;
  background-color: #739CA9;
}

.Projects-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem;
  justify-content: center;
  /* border: blue solid 2px */
}

.web-project-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.web-project {
  position: absolute;
  margin-top: -.4rem;
  height: 6.3rem;
  width: 10.4rem;
  object-fit: cover;
}

.git-p-logo {
  height: 1rem;
}

.proj-name-logo {
  margin-top: .5rem;
  display: flex;
  justify-content: center;
  gap: .5rem;
}

h4 {
  color: white;
}

a {
  text-decoration: none;
}

.aboutme-frame {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 25rem;
  
}

.about-me-p{
  display: flex;
  height: 290px;
  width: 450px;
  background-color: #F6CA8F;
  text-align: center;
  color: #282c34;
  font-size: larger;
  align-items: center;
  padding: 1rem;
  border-radius: 20px;
  position: absolute;
  margin-top: -22.6rem;
  margin-left: 2.2rem;
}

.email-container {
  display: flex;
  align-items: center;
  background-color: #F6CA8F;
  height: 8rem;
  width: 21.5rem;
  border-radius: 20px;
  opacity: .8;
}

.email-add {
  padding-left: 1rem;
  height: 3rem;
  margin-right: -15rem;
}

.git-link-container {
  display: flex;
  gap: 1rem;
  margin-left: 34rem;
  margin-top: -3rem;
}

.git-link-image {
  height: 5rem;
}

.main-main-logo {
  height: 15rem;
}

.tree-main {
  position: absolute;
  margin-left: -3rem;
  margin-top: 8rem;
  height: 40rem;
  opacity: .2;
}

.tagline {
  font-size: 2rem;
  color: #d2a871;
  z-index: 1;
}